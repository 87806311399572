@import '@sanctucompu/basement/index';

/* Font Sizes */
$title: 3.375rem;
$sub-title: 2.25rem;
$heading: 1.75rem;
$sub-heading: 1.125rem;
$body: 0.75rem;
$detail: 0.65625rem;

$mobile-title: 2.25rem;
$mobile-heading: 1.185rem;
$mobile-detail: 0.7rem;

.title {
  font-size: $mobile-title;
  line-height: $mobile-title;
  @include media('md-up') {
    font-size: $title;
    line-height: 4.5rem;
  }
}

.sub-title {
  font-size: $mobile-heading;
  @include media('md-up') {
    font-size: $sub-title;
  }
}

.heading {
  font-size: $mobile-heading;
  line-height: 1.75rem;

  @include media('md-up') {
    font-size: $heading;
    line-height: 2.4375rem;
  }
}

.sub-heading {
  font-size: $sub-heading;
}

.body {
  font-size: $body;
  line-height: 1.125rem;
}

.detail {
  font-size: $detail;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.heading-underline {
  border-bottom: 2px solid color('white');
  @include media('md-up') {
    border-bottom: 3px solid color('white');
  }
}

.error {
  @extend .mt_5;
  font-size: $detail;
}
