@import 'app/styles/settings';

.Button {
  border-radius: 0;

  &--primary {
    text-transform: lowercase;
    font-family: $akkurat;
    border: 0;
    font-size: $sub-heading;
    padding: 0.75rem 1.5rem;
    width: 16rem;
    color: color('black');
    background: color('white');
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &--hollow {
    text-transform: lowercase;
    font-family: $akkurat;
    border: 2px solid color('white');
    font-size: $mobile-heading;
    padding: 0.5rem;
    width: 100%;
    max-width: 12rem;
    color: color('white');
    background: transparent;
    cursor: pointer;

    @include media('md-up') {
      font-size: $sub-heading;
      max-width: 16rem;
      padding: 0.75rem 1.5rem;
    }

    transition:
      background-color $transition-short,
      color $transition-short;
    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled {
      color: color('black');
      background-color: color('white');
    }

    &:disabled {
      cursor: not-allowed;
      color: color('gray-dark');
      border-color: color('gray-dark');
    }
  }

  &--hollow-preserve-case {
    @extend .Button--hollow;
    text-transform: none;
  }

  &--hollow__stretch {
    @extend .Button--hollow;
    max-width: none;
    @include media('md-up') {
      max-width: none;
    }
  }

  &--icon {
    background: transparent;
    border: 0;
    cursor: pointer;

    img {
      height: 1.1875rem;
      @include media('md-up') {
        height: 1.6875rem;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &--title-icon {
    background: transparent;
    border: 0;
    cursor: pointer;

    img {
      height: 1.75rem;
      @include media('md-up') {
        height: 2.75rem;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &--list-item-icon {
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;

    padding-left: 0;

    img {
      height: 1.5rem;
      width: auto;
      @include media('md-up') {
        height: 2.1rem;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &--warning {
    text-transform: lowercase;
    font-family: $akkurat;
    background: transparent;
    border: 0;
    height: 1rem;
    width: 1rem;
    padding: 0;
    cursor: pointer;

    @include media('md-up') {
      height: 3.125rem;
      width: 3.125rem;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
