.SupportWarning {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &__Frame__Fg,
  &__Frame__Bg {
    fill: transparent;
    stroke-width: 16px;
  }

  &__Frame__Bg {
    stroke: #ffbf00;
  }

  &__Frame__Fg {
    stroke: #000;
    stroke-dasharray: 24px;
    stroke-dashoffset: 48px;
    animation: dancingants 2s linear infinite;
  }
}

@keyframes dancingants {
  to {
    stroke-dashoffset: 0;
  }
}
