.TransitionLoader {
  &__content {
    opacity: 0;
    transition: opacity $transition;
  }

  &--loaded {
    .TransitionLoader__content {
      opacity: 1;
    }
  }
}
