@import 'app/styles/settings';

.Note {
  border-radius: 0;

  &--list-item-icon {
    background: transparent;
    border: 0;
    outline: none;

    padding-left: 0;

    img {
      height: 1.5rem;
      width: auto;
      @include media('md-up') {
        height: 1.5rem;
      }
    }
  }
}
