@import 'app/styles/settings';

.playlist-table-header {
  flex-basis: 90%;
  @include media('md-up') {
    flex-basis: 94%;
  }
}

.playlist-table-song {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 55%;
}

.playlist-table-artist {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 45%;
}

.truncated-file-name {
  min-width: 50%;
  flex-basis: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-list {
  width: 100%;
}

.upload-list-item {
  min-width: 0;
}

.playlist-table-row {
  // disable ghosting of playlist-item edit link
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  min-width: 0;
  flex-basis: 90%;
  @include media('md-up') {
    flex-basis: 94%;
  }
}

.playlist-table-button {
  flex-basis: 10%;
  @include media('md-up') {
    flex-basis: 6%;
  }
}

.clickable {
  cursor: pointer;
}

.sort-arrow-up {
  background: transparent;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include media('md-up') {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  img {
    position: absolute;
    bottom: 0;
    transform: rotate(90deg);
    height: 1.5rem;
    @include media('md-up') {
      height: 2rem;
    }
  }
}

.sort-arrow-down {
  background: transparent;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include media('md-up') {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  img {
    position: absolute;
    bottom: 0rem;
    transform: rotate(-90deg);
    height: 1.5rem;
    @include media('md-up') {
      height: 2rem;
    }
  }
}
