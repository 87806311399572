@import 'app/styles/settings';

@mixin BaseInput {
  > div {
    position: relative;
    padding-bottom: 1.25rem;
  }

  input,
  select {
    @extend .color-white;
    @extend .my_5;

    font-size: $sub-heading;
    font-family: $akkurat;
    order: -1;

    background-color: transparent;
    border-radius: 0;
    border-width: 0 0 2px 0;
    border-color: color('white');
    letter-spacing: 1px;
    -webkit-appearance: none;

    &:active,
    &:focus,
    &:placeholder-shown,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: color('white');
      -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
      -webkit-transition-delay: 99999s;
      transition-delay: 99999s;
      box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
      outline-style: none;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
}

@mixin PrimaryInput {
  @include BaseInput;
  @include media('md-up') {
    font-size: $heading;
    border-width: 0 0 2px 0;
  }

  label {
    position: absolute;
    bottom: 0;
    font-size: $body;
    color: color('white');
    line-height: 1;

    @include media('md-up') {
      color: color('white');
    }
  }

  .validation-error {
    position: absolute;
    bottom: -24px;
    left: 0;
    text-align: right;
    font-size: $mobile-detail;
    color: color('red');

    @include media('md-up') {
      font-size: $detail;
    }
  }
}

@mixin SelectNotMultiple {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('/assets/images/icons/dropdown.svg');
}

.Input {
  &--primary {
    @extend .w100;
    @include PrimaryInput;
    input,
    select {
      @extend .w100;
    }

    select:not([multiple]) {
      @include SelectNotMultiple;
      padding-right: 2rem;
    }
  }
  &--primary-auto-width {
    @include PrimaryInput;
    select:not([multiple]) {
      @include SelectNotMultiple;
      padding-right: 3rem;
    }
  }
  &--secondary {
    @include BaseInput;
    input {
      @extend .w100;
    }
  }
}
