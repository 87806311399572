@import 'defaults';

//
// Returns a color from the $color map. Used color('blue') for example.
//
@function color($color-name) {
  @return map-get($colors, $color-name);
}


//
// Returns a media query closure.
//
$media-queries: ();
@each $name, $size in $breakpoints {
  $media-queries: map-merge($media-queries, (
    '#{$name}': 'min-width: #{$size}',
    '#{$name}-up': 'min-width: #{$size}'
  ));

  // If there is a breakpoint above the current breakpoint, create a
  // <breakpoint>-down class.
  $breakpoint-sizes: map-values($breakpoints);
  $next-breakpoint-index: index($breakpoint-sizes, $size) + 1;

  @if $next-breakpoint-index <= length($breakpoint-sizes) {
    $next-breakpoint-size: nth($breakpoint-sizes, $next-breakpoint-index);
    $media-queries: map-merge($media-queries, (
      '#{$name}-down': 'max-width: #{$next-breakpoint-size}'
    ));
  }
}

@function query($query-name) {
  @return "#{map-get($media-queries, $query-name)}";
}

@mixin media($query) {
  @media (query($query)) {
    @content;
  }
}
