@import 'app/styles/settings/transition';

$colors: (
  'transparent': transparent,
  'black': #222,
  'gray-dark': #595958,
  'gray-light': #c6c5c1,
  'red': #ff2d2d,
  'white': #fff,
  'off-white': #d8d8d8,
);

@each $name, $color in $colors {
  .hover-#{$name} {
    transition: all $transition;
    &:hover {
      color: #{$color};
    }
  }
}
