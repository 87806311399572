@import 'app/styles/settings';

.MainMenu {
  border: 3px solid color('white');
  width: 15rem;

  &__link-container:not(:last-of-type) {
    border-bottom: 3px solid color('white');
  }

  &__link {
    transition:
      background-color $transition-short,
      color $transition-short;

    &:hover,
    &:focus,
    &:active {
      color: color('black');
      background-color: color('white');
    }
  }

  @include media('md-up') {
    width: 22rem;
  }
}
