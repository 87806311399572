@import 'settings';
@import '@sanctucompu/basement/index';
@import 'component', 'template';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: $akkurat;
  background: color('black');
  color: color('white');
}

form {
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 600px;
}

a.underlined {
  border-bottom: 2px solid;
}

html,
body,
p {
  margin: 0;
  padding: 0;
}

.wrapper {
  overflow: auto;
  min-height: 100vh;
}

.letter__wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  letter-spacing: 1;
}

.heading__letter__rotater {
  transform: rotate(-20deg) translate(-1px);
}

.subheading__letter__rotater {
  transform: rotate(-20deg) translate(-0.5px);
}

.letter__rotater {
  transform: rotate(-20deg);
}

.container {
  @extend .px2_5;
  width: 100%;
  max-width: 15rem;

  @extend .container__desktop;
}

.container__desktop {
  @extend .px2_5;
  @include media('md-up') {
    max-width: 33rem;
    padding: auto 3rem;
  }
}

.container--wide {
  @extend .container;
  max-width: 33rem;
  @include media('md-up') {
    max-width: 42rem;
  }
}

.container--wider {
  @extend .px4;
  width: 100%;
  max-width: 55rem;
  @include media('md-up') {
    max-width: 55rem;
  }
}

.no-wrap {
  white-space: nowrap;
}

.dropzone {
  border: 3px white dashed;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  max-width: 60vw;
  cursor: pointer;
}

.dz-file-preview {
  display: none;
}

textarea {
  background-color: transparent;
  border: none;
  resize: none;
  outline: none;
  overflow: hidden;
  font-family: $akkurat;
  font-size: 1.125rem;
  color: color('white');
  width: 100%;
  max-width: 33rem;
  line-height: 1.5;
}

.hide {
  display: none;
}
