.InstallButton {
  position: absolute;
  bottom: 4rem;
}

.AddressButton,
.AddressButton__selected {
  font-family: $akkurat;
  padding: 0.5rem;
  width: 100%;
  color: color('white');
  background: transparent;
  cursor: pointer;
  border: none;

  &:focus {
    outline: 0 !important;
  }

  &:after {
    content: '';
    height: 0.25rem;
    background-color: color('white');
    width: 100%;
    margin-top: 0.5rem;
  }
}

.AddressButton {
  &:after {
    visibility: hidden;
  }

  &:hover:after,
  &:focus:after {
    visibility: visible;
  }
}
