.FollowButtonContainer {
  min-width: 4rem;
}

.InvalidPodcastError {
  color: red;
}

.PodcastInput {
  overflow: hidden;
  text-overflow: ellipsis;
}
