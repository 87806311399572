@import 'app/styles/settings';

#progress-bar-container {
  height: 25px;
  width: 60%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#progress-bar {
  height: 2px;
  width: 100%;
  background-color: white;
  position: relative;
  margin: 0 auto;
}

#progress-indicator {
  background-color: white;
  height: 10px;
  width: 10px;
  position: absolute;
  top: -4px;
}

.audio-button-container {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
