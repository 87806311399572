@import 'app/styles/settings';

.fixed-back {
  position: fixed;
  left: 1.3125rem;

  @include media('md-up') {
    left: 3rem;
  }
}

.fixed-cancel {
  position: fixed;
  right: 1.3125rem;

  @include media('md-up') {
    right: 3rem;
  }
}
