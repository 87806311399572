/* This file contains overrides to the form-field component */
/* inherited from ember-form-for */

input:focus + .form-field--errors {
  opacity: 0;
}
.form-field--errors {
  opacity: 0;
}
.form-field.display-errors .form-field--errors {
  opacity: 1;
}
