@import 'app/styles/settings';

.Label {
  color: color('white');
  font-family: $akkurat;
  font-size: $body;
  letter-spacing: 1px;
}

.Label--error {
  color: color('red');
}
