@import
  'defaults',
  'functions';

@function calculate-padding($ratio) {
  $width: nth($ratio, 1);
  $height: nth($ratio, 2);
  @return ($height / $width) * 100%;
}

@each $name, $ratio in $aspect-ratios {
  .aspect-#{$name}::before {
    padding-top: calculate-padding($ratio);
  }
};

[class^='aspect'],
[class*=' aspect'] {
  position: relative;
  &::before {
    display: block;
    content: ' ';
    width: 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
